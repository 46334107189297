document.addEventListener("DOMContentLoaded", function () {
    // Sélectionne toutes les questions de la FAQ
    const faqQuestions = document.querySelectorAll('.faq-question');

    faqQuestions.forEach(question => {
        question.addEventListener('click', function () {
            // Trouve la réponse associée à la question cliquée
            const faqResponse = this.nextElementSibling;

            // Si la réponse est actuellement fermée (hauteur 0), on l'ouvre
            if (faqResponse.style.height === '0px' || faqResponse.style.height === '') {
                // Ferme toutes les autres réponses
                document.querySelectorAll('.faq-response').forEach(response => {
                    response.style.height = '0';
                    response.parentElement.querySelector('.faq-trigger-icon').style.transform = 'rotate(0deg)';
                });

                // Ouvre la réponse courante
                faqResponse.style.height = faqResponse.scrollHeight + 'px';
                this.querySelector('.faq-trigger-icon').style.transform = 'rotate(90deg)';
            } else {
                // Ferme la réponse si elle est déjà ouverte
                faqResponse.style.height = '0';
                this.querySelector('.faq-trigger-icon').style.transform = 'rotate(0deg)';
            }
        });
    });
});
