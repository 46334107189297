const menuIcon = document.getElementById("top-bar-menu-icon")
const topBar = document.getElementById("top-bar");
const bar1 = document.getElementById("bar1");
const bar2 = document.getElementById("bar2");
const bar3 = document.getElementById("bar3");
const logo = document.getElementById("top-bar-logo-img");
const topnav = document.getElementById("topnav");
const links = document.getElementsByClassName("topnav-link");
const sticky = topBar.offsetTop;


// Sélectionner tous les éléments avec la classe accordion-trigger
const accordionTriggers = document.querySelectorAll('.accordion-trigger');

accordionTriggers.forEach(trigger => {
    trigger.addEventListener('click', function(e) {
        e.preventDefault(); // Empêche le comportement par défaut du lien

        // Sélectionner le sous-menu associé
        const subMenu = this.nextElementSibling;

        // Vérifier si le sous-menu est déjà visible
        const isVisible = subMenu.classList.contains('show');

        // Fermer tous les sous-menus ouverts et remettre les icônes à leur état d'origine
        document.querySelectorAll('.subnav.show').forEach(menu => {
            menu.classList.remove('show');
        });
        document.querySelectorAll('.accordion-trigger.active').forEach(activeTrigger => {
            activeTrigger.classList.remove('active');
        });

        // Si le sous-menu n'était pas visible, l'ouvrir et ajouter la classe active au trigger
        if (!isVisible) {
            subMenu.classList.add('show');
            this.classList.add('active');
        }
    });
});


if (menuIcon) {
    menuIcon.addEventListener('click',function(){
        console.log('click ok')
        const bar1 = document.getElementById("bar1")
        const bar2 = document.getElementById("bar2")
        const bar3 = document.getElementById("bar3")
        const mainMenu = document.getElementById("sidenav-wrapper")
        const topBar = document.getElementById("top-bar")

        if (bar1.classList.contains("change") && bar2.classList.contains("change") && bar3.classList.contains("change")) {
            // Fermeture du menu
            // document.getElementById('main').style.display = 'block';
            mainMenu.style.left = '-100vw';
            mainMenu.style.boxShadow = 'none';
            bar1.classList.remove('change')
            bar2.classList.remove('change')
            bar3.classList.remove('change')
        } else {
            // Overture du menu
            // document.getElementById('main').style.display = 'none';
            mainMenu.style.left = '16px';
            mainMenu.style.boxShadow = '0 0 30px rgba(0,0,0,.2)';
            bar1.classList.add('change')
            bar2.classList.add('change')
            bar3.classList.add('change')
        }

    });
}

// When the user scrolls the page, execute myFunction
window.onscroll = function () {
    animateTopbar()
};

window.onload = function () {
    animateTopbar()
};

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function animateTopbar() {
    if (window.pageYOffset > sticky) {
        topBar.classList.add("sticky");
        logo.src = "/images/logo/logo_2024_gradient.png";
        bar1.classList.add('colored')
        bar2.classList.add('primary')
        bar3.classList.add('colored')
        for (i = 0; i < links.length; i++) {
            links[i].classList.add("scrolled");
        }
    } else {
        topBar.classList.remove("sticky");
        // logo.style.height = "120px";
        // logo.style.marginTop = "20px";
        logo.src = "/images/logo/logo_2024_white.png";
        bar1.classList.remove('colored')
        bar2.classList.remove('primary')
        bar3.classList.remove('colored')
        for (i = 0; i < links.length; i++) {
            links[i].classList.remove("scrolled");
        }
    }
}