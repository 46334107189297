// // this loads jquery, but does *not* set a global $ or jQuery variable
// const $ = require('jquery');
// // create global $ and jQuery variables
// global.$ = global.jQuery = $;

/* Global */
import './scss/front/main.scss'
import './scss/front/home/home_hero.scss'

import './js/front/sidenav/_sidenav'
import './scss/front/page_hero/_page_hero.scss'
import './scss/front/_breadcrumb.scss'
import './scss/front/footer/_footer.scss'
import './scss/front/faq/_faq_block.scss'
import './js/front/faq/_faq'
import './scss/front/strengths/_strengths_overview.scss'
import './scss/front/contact/_contact_page.scss'
import './scss/front/_cta.scss'
import './scss/front/services/_services_overview.scss'
import './scss/front/services/_service_block.scss'
import './scss/front/about/_about_block.scss'
import './scss/front/about/_about_page.scss'


/** Fontawesome **/
import './vendor/fontawesome-pro-6.5.1-web/css/all.min.css'
import './vendor/fontawesome-pro-6.5.1-web/js/all.min'
import './vendor/fontawesome-pro-6.5.1-web/js/fontawesome.min'


// Fonction executée lors de l'utilisation du clic droit.
// $(document).bind("contextmenu",function()
// {
// // Si vous voulez ajouter un message d'alerte
//     alert('Merci de respecter le travail du webmaster en ne copiant pas le contenu sans autorisation');
// // On indique au navigateur de ne pas réagir en cas de clic droit.
//     return false;
// });
